import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();
  const url = useRequestURL();

  const keycloakBaseUrl = `${config.public.auth.url}/realms/${config.public.auth.realm}/protocol/openid-connect`;
  let toRedirectPath = '';

  toRedirectPath = config.public.websiteUrl + from.fullPath;

  const keycloakLoginUrl =
    `${keycloakBaseUrl}/auth` +
    `?client_id=${encodeURIComponent(
      config.public.auth.clientId,
    )}&redirect_uri=${encodeURIComponent(
      `${toRedirectPath}`,
    )}&state=${encodeURIComponent(
      uuidv4(),
    )}&response_mode=query&response_type=code&scope=${encodeURIComponent(
      'openid email profile gportal',
    )}`;

  if (!authStore.isAuthenticated) {
    return navigateTo(keycloakLoginUrl, { external: true });
  }
});
